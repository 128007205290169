@import "~@/styles/variables.scss";





































































































































































.bm-view {
    width: 100%;
    height: 300px;
}
